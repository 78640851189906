<template>
    <ListaGadget />
</template>

<script>
import ListaGadget from '@/components/Gadget/ListaGadget'
export default {
  components: {ListaGadget},
  data(){
    return{}
  }
}
</script>

<style>

</style>