<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Gadgets</h3>
        <p>Listagem de gadgets</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn" v-if="loading" @click="$router.push('/criar/gadget')">
        <v-icon color="#f9f9f2" size='28'>mdi-plus-circle-outline</v-icon>
      </button>
    </div>
    <section class="wrapper-list" v-if="loading">
      <!-- lista de usuarios -->
      <v-data-table 
        :items="items"
        :headers="headers"
        :items-per-page="6"
        @click:row="(item) => $router.push(`/lista/gadget/${item.id}`)">
      </v-data-table>
    </section>
    <ListSkeleton v-if="!loading"/>
  </v-container>
</template>

<script>
import GadgetServico from '@/services/gadget.service.js'
import ListSkeleton from '@/components/Loading/ListSkeleton.vue' 
export default {
  components: {ListSkeleton},
  data(){
    return{
      headers: [
        { text: 'Id',  value: 'id' },
        { text: 'Nome', value: 'nome' },
        { text: 'Tipo', value: 'nometipo' },
      ],
      items: [],
      loading: false,
    }
  },
  methods:{
    async requisitarGadgets(){
      this.items = await GadgetServico.buscar().then(res=> res.data)
    }
  },
  async mounted(){
    await this.requisitarGadgets()
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
      margin: 0 auto;
    width: 60vw;
    max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

</style>